<template>
  <Menu
    :id="id"
    class="right-align-menu"
    ref="menu"
    popup
    :model="menuOptions"
  />

  <ContactUsDialog
    v-model:visible="isContactUsDialogVisible"
  />
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores';
import { ROUTE_ADVERTISER_PROFILE, ROUTE_LOGIN } from '@/router/routes';
import ContactUsDialog from '@/components/contactUsDialog';

export default {
  components: {
    ContactUsDialog,
  },
  props: {
    id: {
      type: String,
      default: 'profile-menu',
    },
  },
  data() {
    return {
      menuOptions: [
        {
          label: 'Profile',
          command: () => this.$router.push({ name: ROUTE_ADVERTISER_PROFILE }),
        },
        {
          label: 'Logout',
          command: () => this.logout(),
        },
        {
          separator: true,
        },
        {
          icon: 'pi pi-email',
          label: 'Contact Us',
          command: () => {
            this.isContactUsDialogVisible = true;
          },
        },
      ],
      isContactUsDialogVisible: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    toggle(event) {
      if (this.$refs.menu) {
        this.$refs.menu.toggle(event);
      }
    },
    logout() {
      this.authStore.logout();
      this.$router.push({
        name: ROUTE_LOGIN,
      });
    },
  },
};
</script>
