export const AD_RUN_SLOT_PRE_ROLL = 'pre-roll';
export const AD_RUN_SLOT_MID_ROLL = 'mid-roll';
export const AD_RUN_SLOT_POST_ROLL = 'post-roll';

export const AD_RUN_SLOT_PRE_ROLL_LABEL = 'Pre-roll';
export const AD_RUN_SLOT_MID_ROLL_LABEL = 'Mid-roll';
export const AD_RUN_SLOT_POST_ROLL_LABEL = 'Post-roll';

export const AD_RUN_SLOT_OPTIONS = [
  {
    value: AD_RUN_SLOT_PRE_ROLL,
    label: AD_RUN_SLOT_PRE_ROLL_LABEL,
  },
  {
    value: AD_RUN_SLOT_MID_ROLL,
    label: AD_RUN_SLOT_MID_ROLL_LABEL,
  },
  {
    value: AD_RUN_SLOT_POST_ROLL,
    label: AD_RUN_SLOT_POST_ROLL_LABEL,
  },
];
