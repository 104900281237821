import ekozAxios from './instance/axios';

export const createTextToSpeech = ({
  userId,
  text,
  demoVoice,
}) => {
  const formData = new FormData();

  formData.append('text', text);

  if (userId) {
    formData.append('user_id', userId);
  }
  if (demoVoice) {
    formData.append('demo_voice', 'true');
  }

  return ekozAxios.request({
    method: 'post',
    url: '/manual_voice/generate_tts.php',
    data: formData,
  });
};
