<template>
  <BaseFieldContainer>
    <VeeField
      :name="fieldName"
      :modelValue="modelValue"
      v-slot="{ errors }"
    >
      <label
        v-if="fieldLabel"
        :for="fieldId"
        :class="{ 'text-red-400': errors.length > 0}"
      >
        {{ fieldLabel }}
      </label>
      <slot name="helperText">
        <small
          v-if="helperText"
          :id="`${fieldId}-help`"
          v-html="helperText"
        />
      </slot>
      <FileUpload
        :pt="{
          buttonbar: {
            class: 'p-0 border-0',
          },
          content: {
            class: {
              'border-dashed': true,
              'border-red-400': errors.length > 0
            },
            style: {
              borderRadius: '6px',
              borderTop: '1px solid #e2e8f0',
            },
          },
        }"
        v-bind="{
          ...defaultAttrs,
          ...$attrs,
        }"
        @select="onFileSelect"
        ref="fileUpload"
        :aria-describedby="helperText ? `${fieldId}-help` : ''"
      >
        <template #header><div><!-- empty --></div></template>
        <template #content="{}">
          <div class="text-center">
            <p class="mb-0">
              <i
                :style="{
                  fontSize: '1.5em',
                }"
                class="pi pi-upload text-primary"
              />
            </p>
            <p class="mt-2">
              {{ dragText }}
            </p>
          <Button
            link
            label="Browse"
            @click="() => {
              this.$refs.fileUpload?.$refs?.fileInput?.click();
            }"
          />
          </div>
        </template>
      </FileUpload>
      <small
        v-if="errors.length > 0"
        class="text-red-400"
      >
        {{ errors[0] }}
      </small>

      <ul class="pl-0 list-none">
        <li
          class="mt-2 selected-file px-3 py-2 flex justify-content-between"
          v-for="(file, index) in value"
          :key="index"
        >
          <div class="flex align-items-center ellipsis">
            <i class="pi pi-file text-gray-500 text-xl" />
            <span class="ml-2">{{ file.name }}</span>
          </div>

          <div class="flex align-items-center pl-2">
            <Divider layout="vertical" />
            <span
              v-if="file.size"
              class="text-sm text-right"
            >
              {{ $filters.formatBytes(file.size, 0) }}
            </span>
            <Button
              class="ml-2"
              icon="pi pi-times"
              text
              plain
              @click="onRemoveFile(index)"
            />
          </div>
        </li>
      </ul>
    </VeeField>
  </BaseFieldContainer>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      validator: (prop) => prop instanceof Array || prop === null,
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
    helperText: {
      type: String,
    },
    dragText: {
      type: String,
      default: 'Drag your file here',
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', [...value]);
      },
    },
    defaultAttrs() {
      return {
      };
    },
  },
  methods: {
    onFileSelect({ files }) {
      if (this.$attrs.multiple === true) {
        this.value = [
          ...this.value,
          ...files,
        ];
      } else {
        this.value = [...files];
      }

      this.clearFileuploadFiles();
    },
    onRemoveFile(index) {
      const selectedFiles = this.value.filter((file, fileIndex) => fileIndex !== index);

      this.value = selectedFiles;
    },
    clearFileuploadFiles() {
      if (
        this.$refs.fileUpload
        && this.$refs.fileUpload.files
        && this.$refs.fileUpload.files.length > 0
      ) {
        const fileLength = this.$refs.fileUpload.files.length;

        for (let i = fileLength; i > 0; i -= 1) {
          this.$refs.fileUpload.remove(i - 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-file {
  border: 1px #e2e8f0 solid;
  border-radius: 6px;
}
</style>
