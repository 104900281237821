// TODO - 15s ad length is deprecated. remove in future
export const AD_LENGTH_15_SECONDS = 15;
export const AD_LENGTH_30_SECONDS = 30;
export const AD_LENGTH_60_SECONDS = 60;
export const AD_LENGTH_90_SECONDS = 90;

export const AD_LENGTH_OPTIONS = [
  {
    value: AD_LENGTH_30_SECONDS,
    label: '30 seconds',
  },
  {
    value: AD_LENGTH_60_SECONDS,
    label: '60 seconds',
  },
  {
    value: AD_LENGTH_90_SECONDS,
    label: '90 seconds',
  },
];
