<template>
  <Dialog
    class="campaign-invoices-dialog"
    :visible="visible"
    dismissableMask
    modal
    @update:visible="$emit('update:visible', $event)"
    header="Invoices"
    :pt="{
      header: {
        class: 'bg-gray-100',
      },
      content: {
        class: 'bg-gray-100',
      },
      footer: {
        class: 'bg-gray-100',
      },
    }"
  >
    <div v-if="campaign">
      <p>
        These are the invoices for your campaign <strong>{{ campaign.campaign_name}}</strong>.
      </p>
      <p
        v-if="formattedInvoices.length === 0"
        class="mt-0">
        No Invoices found.
      </p>

      <div v-else>
        <Card
          class="mb-2"
          v-for="invoice in formattedInvoices"
          :key="invoice.invoice_id"
        >
          <template #content>
            <div class="line-height-3">
                <span class="mr-2 text-gray-500 text-lg">
                  {{ invoice.formattedDate }}
                </span>
                <Chip
                  :class="['capitalize text-sm', invoice.statusChipStyle]"
                  :label="invoice.status"
                />
                <br />
                <span class="font-bold text-3xl">
                  {{ invoice.formattedAmount }}
                </span>
            </div>
          </template>
          <template #footer>
            <div class="flex justify-content-end">
              <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
              <a
                v-if="invoice.hosted_invoice_url"
                :href="invoice.hosted_invoice_url"
                target="_blank"
              >
                <Button
                  size="small"
                  label="View"
                  icon="pi pi-external-link"
                />
              </a>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button
          text
          plain
          label="Close"
          @click="$emit('update:visible', false)"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    campaign: {
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
  },
  computed: {
    formattedInvoices() {
      if (!this.campaign || !this.campaign.invoices) {
        return [];
      }

      return this.campaign.invoices.map((item) => {
        const formattedAmount = item.amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'USD',
        }).toString();

        const formattedDate = DateTime.fromISO(item.date)
          .toLocaleString(DateTime.DATE_SHORT);

        const statusChipStyle = item.status === 'paid'
          ? 'bg-light-green text-green-500'
          : '';

        return {
          ...item,
          formattedAmount,
          statusChipStyle,
          formattedDate,
        };
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.campaign-invoices-dialog {
  width: 300px;

  @media screen and (
    min-width: #{map-get($breakpoints, 'sm')}
  ) {
    width: 500px;
  }
}
</style>

<style lang="scss" scoped>
.bg-light-green {
  background-color: #f4fdf7;
}
</style>
