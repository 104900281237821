<template>
  <section>
    <MenuBar
      class="menu-bar"
      :model="menuItems"
      :pt="{
        menu: {
          class: 'align-items-baseline',
        },
      }"
    >
      <template #start>
        <router-link
          class="inline-block px-2"
          to="/"
        >
          <img
            class="header-logo"
            :src="logo"
            alt="Ekoz.ai logo"
          />
        </router-link>
      </template>
      <template #item="{ item, props }">
        <router-link
          v-if="item.route"
          v-slot="{ href, navigate, isExactActive }"
          :to="item.route"
          custom
        >
            <a
              :class="{
                'text-primary': isExactActive,
              }"
              :href="href"
              v-bind="props.action"
              @click="navigate"
            >
                <span
                  v-if="item.icon"
                  class="mr-2"
                  :class="item.icon"
                />
                <span>{{ item.label }}</span>
            </a>
        </router-link>
        <a
          v-else
          :href="item.url"
          :target="item.target"
          v-bind="props.action"
        >
            <span
              v-if="item.icon"
              class="mr-2"
              :class="item.icon"
            />
            <span>{{ item.label }}</span>
        </a>
      </template>
      <template #end>
        <div class="flex">
          <!-- <Button
            text
            plain
            aria-haspopup="true"
            aria-controls="notifications-overlay"
            @click="(event) => this.$refs.notificationOverlay.toggle(event)"
          >
            <i
              class="pi pi-bell"
              v-badge.contrast="3"
            />
          </Button> -->
          <NotificationOverlay
            id="notification-overlay"
            ref="notificationOverlay"
          />
          <Button
            text
            plain
            aria-haspopup="true"
            aria-controls="profile-menu"
            @click="(event) => this.$refs.profileMenu.toggle(event)"
          >
            <Avatar
              :label="myUserStore.userInitials"
              shape="circle"
            />
            <i class="pi pi-angle-down ml-2" />
          </Button>
          <ProfileMenu
            id="profile-menu"
            ref="profileMenu"
          />
        </div>
      </template>
    </MenuBar>
    <router-view />
  </section>
</template>

<script>
import { mapStores } from 'pinia';
import {
  useMyUserStore,
  useCategoriesStore,
  useProgramsStore,
} from '@/stores';
import logo from '@/assets/logo.png';
import { ROUTE_ADVERTISER_CAMPAIGNS, ROUTE_ADVERTISER_ORGANIZATION, ROUTE_ADVERTISER_PROFILE } from '@/router/routes';
import NotificationOverlay from './components/notificationOverlay';
import ProfileMenu from './components/profileMenu';

export default {
  components: {
    NotificationOverlay,
    ProfileMenu,
  },
  data() {
    return {
      logo,
      menuItems: [
        {
          label: 'Campaigns',
          route: {
            name: ROUTE_ADVERTISER_CAMPAIGNS,
          },
        },
        {
          label: 'Organization',
          route: {
            name: ROUTE_ADVERTISER_ORGANIZATION,
          },
        },
        {
          label: 'Profile',
          route: {
            name: ROUTE_ADVERTISER_PROFILE,
          },
        },
      ],
    };
  },
  computed: {
    ...mapStores(useMyUserStore, useCategoriesStore, useProgramsStore),
  },
  mounted() {
    this.categoriesStore.getCategories();
  },
};
</script>

<style lang="scss" scoped>
.header-logo {
  width: 80px;

  @media (min-width: 961px) {
    width: 120px;
  }
}

.menu-bar {
  border-width: 0 0 1px 0;
  border-radius: 0;

  :deep(.p-menubar-start) {
    order: 1;

    @media (min-width: 961px) {
      order: 0;
    }
  }
  :deep(.p-menubar-end) {
    order: 2;
  }
}
</style>
