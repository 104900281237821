import ekozAxios from './instance/axios';

export const createGeneratedAd = ({
  userId,
  adFile,
  scriptMinutes = 0,
  scriptSeconds = 30,
  demoVoice,
}) => {
  const formData = new FormData();

  formData.append('script_minutes', scriptMinutes);
  formData.append('script_seconds', scriptSeconds);
  formData.append('ad_file', adFile);

  if (userId) {
    formData.append('user_id', userId);
  }
  if (demoVoice) {
    formData.append('demo_voice', 'true');
  }

  return ekozAxios.request({
    method: 'post',
    url: '/generated_ads/new_generated_ad.php',
    data: formData,
  });
};

export const setApprovalGeneratedAd = ({
  generatedAdId,
  approved,
  disapprovedReason,
}) => ekozAxios.request({
  method: 'post',
  url: '/generated_ads/set_approval_generated_ad.php',
  data: {
    approved,
    disapproved_reason: disapprovedReason,
    generated_ad_id: generatedAdId,
  },
});

export const uploadPremadeAd = ({
  adFile,
  userId,
  programId,
  campaignId,
  adId,
}) => {
  const formData = new FormData();

  formData.append('mp3_file', adFile);
  formData.append('user_id', userId);
  formData.append('program_id', programId);
  formData.append('campaign_id', campaignId);
  formData.append('ad_id', adId);

  return ekozAxios.request({
    method: 'post',
    url: '/generated_ads/upload_premade_ad.php',
    data: formData,
  });
};
