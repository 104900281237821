<template>
  <Message
    v-if="bidRecommendation
      && bidRecommendation.min !== null
      && bidRecommendation.max !== null"
    class="m-0"
    severity="secondary"
    icon="pi pi-info-circle"
    :closable="false"
    :pt="{
      icon: {
        class: 'mt-1 text-xl align-self-start',
      },
      text: {
        class: 'font-normal text-sm',
      },
    }"
  >
    Based on your ad preferences we recommend a CPM
    of <strong>${{ bidRecommendation.min }}</strong>
    - <strong>${{ bidRecommendation.max }}</strong>
  </Message>
</template>

<script>
import { getBidRecommendation } from '@/utils/bidRecommendations';

export default {
  props: {
    userType: String,
    adRunSlot: String,
    adLength: Number,
  },
  computed: {
    bidRecommendation() {
      return getBidRecommendation(
        this.userType,
        this.adRunSlot,
        this.adLength,
      );
    },
  },
};
</script>
