<template>
  <div class="create-organization-container">
    <section class="form-container flex-grow-1">
      <div class="grid">
        <div class="col-12 sm:col-4 hidden sm:block">
          <Card
            class="bg-primary organization-card"
            :pt="{
              body: {
                style: {
                  height: '100%',
                },
              },
              content: {
                style: {
                  height: '100%'
                }
              }
            }"
          >
            <template #content>
              <Avatar
                :icon="avatarIcon"
                class="text-white bg-grey organization-building"
                size="xlarge"
                shape="circle"
              />
            </template>
          </Card>
        </div>
        <div class="col-12 sm:col-8">
          <h2 class="text-2xl">
            Create Organization
          </h2>
          <VeeForm
            v-slot="{ handleSubmit }"
            :validation-schema="organizationSchema"
            as="div"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              id="organization-form"
              @submit.prevent="handleSubmit($event, onSubmitOrganization)"
            >
              <BaseInput
                v-model="organizationForm.name"
                fieldId="name"
                fieldName="name"
                fieldLabel="Name"
              />

              <Divider />
            </form>
          </VeeForm>

          <VeeForm
            class="mt-2"
            v-slot="{ handleSubmit }"
            :validation-schema="userSchema"
            as="div"
            @invalidSubmit="onInvalidSubmit"
          >
            <form
              @submit.prevent="handleSubmit($event, onSubmitUser)"
            >
              <BaseFieldContainer>
                <VeeField
                  name="email"
                  v-model="userForm.email"
                  v-slot="{ errors, field }"
                >
                  <label
                    for="email"
                    :class="{ 'text-red-400': errors.length > 0}"
                  >
                    Invite users by email
                  </label>
                  <InputGroup>
                    <InputText
                      id="email"
                      v-bind="field"
                      :invalid="errors.length > 0"
                    />
                    <Button
                      type="submit"
                      label="Invite"
                    />
                  </InputGroup>
                  <small
                    v-if="errors.length > 0"
                    class="text-red-400"
                  >
                    {{ errors[0] }}
                  </small>
                </VeeField>
              </BaseFieldContainer>
            </form>
          </VeeForm>

          <Card class="mt-2 shadow-none border-1 border-gray-300">
            <template #content>
              <DataTable
                :value="invitedUsers"
                dataKey="id"
              >
                <Column
                  field="email"
                  header="Email"
                />
                <Column
                  header="Status"
                >
                  <template #body>
                    <Chip class="bg-blue-100 text-blue-500">Pending</Chip>
                  </template>
                </Column>
                <Column
                  class="w-1p"
                >
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-ellipsis-v"
                      text
                      plain
                      aria-haspopup="true"
                      :aria-controls="`row-menu-${slotProps.data.id}`"
                      @click="(event) => this.$refs[`rowMenu${slotProps.data.id}`].toggle(event)"
                    />
                    <Menu
                      :id="`row-menu-${slotProps.data.id}`"
                      class="right-align-menu"
                      :ref="`rowMenu${slotProps.data.id}`"
                      popup
                      :model="rowOptionsDictionary[slotProps.data.id]"
                    />
                  </template>
                </Column>
                  <template #empty>No invited users found</template>
              </DataTable>
            </template>
          </Card>
        </div>
      </div>
    </section>

    <div class="form-container flex justify-content-end py-4">
      <Button
        v-if="allowSkip"
        text
        plain
        label="Skip for now"
        :disabled="isSubmitting"
        @click="onClickSkip"
      />
      <Button
        class="ml-2"
        label="Next"
        type="submit"
        form="organization-form"
        :loading="isSubmitting"
      />
    </div>
  </div>
</template>

<script>
import { object, string } from 'yup';
import { v4 as uuidv4 } from 'uuid';
import _uniqBy from 'lodash/uniqBy';
import { INVALID_FORM_SUBMISSION_MESSAGE } from '@/utils/messages';

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    allowSkip: {
      type: Boolean,
      default: true,
    },
    avatarIcon: {
      type: String,
      default: 'pi pi-building',
    },
  },
  computed: {
    rowOptionsDictionary() {
      return this.invitedUsers.reduce((acc, item) => {
        acc[item.id] = [
          {
            label: 'Remove',
            command: () => {
              this.invitedUsers = this.invitedUsers.filter((user) => user.id !== item.id);
            },
          },
        ];

        return acc;
      }, {});
    },
  },
  data() {
    return {
      organizationForm: {
        name: '',
      },
      organizationSchema: object({
        name: string().required('Name is required'),
      }),
      userForm: {
        email: '',
      },
      userSchema: object({
        email: string().email().required('Email is required'),
      }),
      invitedUsers: [],
    };
  },
  methods: {
    onInvalidSubmit() {
      this.$toast.add({
        severity: 'warn',
        detail: INVALID_FORM_SUBMISSION_MESSAGE,
      });
    },
    onClickSkip() {
      this.$emit('cancel', true);
    },
    onSubmitOrganization(values) {
      this.$emit('submit', {
        organizationForm: values,
        invitedUsers: this.invitedUsers,
      });
    },
    onSubmitUser({
      email,
    }, {
      resetForm,
    }) {
      this.invitedUsers = _uniqBy([...this.invitedUsers, {
        id: uuidv4(),
        email,
      }], (item) => item.email);

      resetForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  max-width: 800px;
  width: 100%;
}

.create-organization-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-building {
  position: absolute;
  left: 50%;
  bottom: 60%;
  transform: translate(-50%, 50%);
  z-index: 2;
  width: 60%;
  padding-top: 60%;

  :deep(.p-avatar-icon){
    font-size: 1.8em;
    color: var(--gray-300);
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
}

.organization-card {
  min-height: 500px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%;
    background-color: var(--gray-900);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
</style>
