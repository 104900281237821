<template>
  <BaseFieldContainer>
    <VeeField
      :name="fieldName"
      v-model="value"
      v-slot="{ errors, field }"
    >
      <label
        v-if="fieldLabel"
        :for="fieldId"
        :class="{ 'text-red-400': errors.length > 0}"
      >
        {{ fieldLabel }}
      </label>
      <slot name="helperText">
        <small
          v-if="helperText"
          :id="`${fieldId}-help`"
          v-html="helperText"
        />
      </slot>
      <!-- TODO - find out why @blur is not automatically bound when passed in from parent -->
      <InputText
        :id="fieldId"
        :invalid="errors.length > 0"
        v-bind="{
          ...defaultAttrs,
          ...$attrs,
          ...field,
        }"
        @blur="$emit('blur', $event.target.value)"
        :aria-describedby="helperText ? `${fieldId}-help` : ''"
      />
      <CharacterLimit
        v-if="typeof characterLimit === 'number'"
        :limit="characterLimit"
        :textLength="value ? value.length : 0"
      />
      <small
        v-if="errors.length > 0"
        class="text-red-400"
      >
        {{ errors[0] }}
      </small>
    </VeeField>
  </BaseFieldContainer>
</template>

<script>
import CharacterLimit from '@/components/characterLimit';

export default {
  components: {
    CharacterLimit,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
    helperText: {
      type: String,
    },
    characterLimit: {
      type: Number,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    defaultAttrs() {
      return {
        type: 'text',
      };
    },
  },
};
</script>
