<template>
  <div>
    <form @submit.prevent="onSubmit">
      <Message
        :closable="false"
        severity="secondary"
        :pt="{
          icon: {
            class: 'mt-1 text-xl align-self-start',
          },
          text: {
            class: 'font-normal',
          },
        }"
        icon="pi pi-info-circle"
      >
        You must have a payment method on file to create your first campaign.
        Your card will be charged based on the amount of impressions your campaign has received.
        This charge occurs at the end of each month for any running and completed campaigns.

        <p>
          Need help or have any questions? Contact our
          <a
            class="text-primary"
            :href="mailTo"
          >
            support.
          </a>
        </p>
      </Message>
      <div
        ref="paymentElement"
        id='payment-element'
      />
      <div class="flex justify-content-end mt-3">
        <Button
          v-if="paymentElement"
          label="Add Payment Method"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { mapStores } from 'pinia';
import { useMyUserStore } from '@/stores';
import * as api from '@/api';
import { parseMessageFromError } from '@/utils/errors';
import { SUPPORT_EMAIL } from '@/constants';
import { generateBodyText } from '@/utils/support';

const PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

export default {
  props: {
    redirectUrl: String,
  },
  computed: {
    ...mapStores(useMyUserStore),
    mailTo() {
      const subject = 'Ekoz.ai Payment Question';
      const body = generateBodyText({
        userId: this.myUserStore.userId,
        url: window.location.href,
      });

      return `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    },
  },
  data() {
    return {
      stripe: null,
      elements: null,
      paymentElement: null,
    };
  },
  mounted() {
    this.loadPaymentMethodForm();
  },
  methods: {
    async loadPaymentMethodForm() {
      try {
        const res = await api.createSetupIntent();

        this.stripe = await loadStripe(PUBLISHABLE_KEY);

        this.elements = this.stripe.elements({ clientSecret: res.client_secret });

        const options = {
          layout: {
            type: 'accordion',
          },
        };

        this.paymentElement = this.elements.create('payment', options);

        if (this.$refs.paymentElement) {
          this.paymentElement.mount('#payment-element');
        }
      } catch (error) {
        const message = parseMessageFromError(error, 'Error loading payment method form.');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
    async onSubmit() {
      const { error } = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: this.redirectUrl,
        },
      });

      if (error && error.message) {
        this.$toast.add({
          severity: 'error',
          detail: error.message,
        });
      } else if (error) {
        this.$toast.add({
          severity: 'error',
          detail: 'Error confirming payment method.',
        });
      }
    },
  },
};
</script>
