import { defineStore } from 'pinia';
import * as api from '@/api';

export const useAdsStore = defineStore('ads', {
  state: () => ({
    ads: [],
    adsAreLoading: false,
  }),
  getters: {
    getAdById: (state) => (
      (adId) => state.ads.find((item) => Number(item.id) === Number(adId))
    ),
    getAdsByOrganizationId: (state) => (
      // eslint-disable-next-line no-unused-vars
      (organizationId) => state.ads
      // TODO - re-add when org_id exists from API
      // .filter((item) => Number(item.org_id) === Number(organizationId))
    ),
  },
  actions: {
    async getAds({
      adId,
      programIds,
      hostingPlatform,
    } = {}) {
      try {
        this.adsAreLoading = true;

        if (adId) {
          const res = await api.readAds({
            adId,
          });

          this.ads = res.ads;
        } else if (programIds && programIds.length > 0) {
          const res = await api.readAds({
            programIds,
          });

          this.ads = res.ads;
        } else if (programIds && programIds.length === 0) {
          this.ads = [];
        } else if (hostingPlatform) {
          const res = await api.readAds({
            hostingPlatform,
          });

          this.ads = res.ads;
        }
      } finally {
        this.adsAreLoading = false;
      }
    },
  },
});
