import {
  AD_LENGTH_30_SECONDS,
  AD_LENGTH_60_SECONDS,
  AD_LENGTH_90_SECONDS,
  AD_RUN_SLOT_MID_ROLL,
  AD_RUN_SLOT_POST_ROLL,
  AD_RUN_SLOT_PRE_ROLL,
  USER_TYPE_HOST,
} from '@/constants';
import { getBidRecommendation } from '@/utils/bidRecommendations';

const getMinimumCpm = (adPreferences, adRunSlot, adLength) => {
  const matchingItem = adPreferences
    ? adPreferences.find((item) => item.adRunSlot === adRunSlot
      && item.length === adLength
      && typeof item.min_cpm === 'number')
    : null;

  const bidRecommendation = getBidRecommendation(USER_TYPE_HOST, adRunSlot, adLength);
  const bidMinimumRecommendation = bidRecommendation.min || 0;

  return matchingItem
    ? matchingItem.min_cpm
    : bidMinimumRecommendation;
};

const getDisabledStatus = (adPreferences, adRunSlot, adLength) => {
  const matchingItem = adPreferences
    ? adPreferences.find((item) => item.adRunSlot === adRunSlot
      && item.length === adLength
      && typeof item.min_cpm === 'number'
      && typeof item.disabled === 'boolean')
    : null;

  return matchingItem
    ? matchingItem.disabled
    : false;
};

/**
 * Sanitize ad preferences. This will
 * add any missing combinations that are not included in the passed in adPreferences
 * or will return a full instance of adPreferences if no passed in value is provided
 *
 * @param {Array|null} [adPreferences = null]
 * @return {Array}
 */
export const sanitizeAdPreferences = (adPreferences = null) => ([
  {
    adRunSlot: AD_RUN_SLOT_PRE_ROLL,
    length: AD_LENGTH_30_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_PRE_ROLL, AD_LENGTH_30_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_PRE_ROLL, AD_LENGTH_30_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_PRE_ROLL,
    length: AD_LENGTH_60_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_PRE_ROLL, AD_LENGTH_60_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_PRE_ROLL, AD_LENGTH_60_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_PRE_ROLL,
    length: AD_LENGTH_90_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_PRE_ROLL, AD_LENGTH_90_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_PRE_ROLL, AD_LENGTH_90_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_MID_ROLL,
    length: AD_LENGTH_30_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_MID_ROLL, AD_LENGTH_30_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_MID_ROLL, AD_LENGTH_30_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_MID_ROLL,
    length: AD_LENGTH_60_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_MID_ROLL, AD_LENGTH_60_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_MID_ROLL, AD_LENGTH_60_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_MID_ROLL,
    length: AD_LENGTH_90_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_MID_ROLL, AD_LENGTH_90_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_MID_ROLL, AD_LENGTH_90_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_POST_ROLL,
    length: AD_LENGTH_30_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_POST_ROLL, AD_LENGTH_30_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_POST_ROLL, AD_LENGTH_30_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_POST_ROLL,
    length: AD_LENGTH_60_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_POST_ROLL, AD_LENGTH_60_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_POST_ROLL, AD_LENGTH_60_SECONDS),
  },
  {
    adRunSlot: AD_RUN_SLOT_POST_ROLL,
    length: AD_LENGTH_90_SECONDS,
    min_cpm: getMinimumCpm(adPreferences, AD_RUN_SLOT_POST_ROLL, AD_LENGTH_90_SECONDS),
    disabled: getDisabledStatus(adPreferences, AD_RUN_SLOT_POST_ROLL, AD_LENGTH_90_SECONDS),
  },
]);
