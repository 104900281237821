<template>
  <div class="grid">
    <div class="col-12">
      <BaseInput
        :modelValue="rssUrl"
        @update:modelValue="$emit('update:rssUrl', $event)"
        :fieldId="rssUrlFieldName"
        :fieldName="rssUrlFieldName"
        fieldLabel="Spreaker RSS"
        @blur="$emit('blurRssUrl', $event)"
      >
        <template #helperText>
          <small :id="`${rssUrlFieldName}-help`">
            Enter your spreaker rss feed. This field will try to import your settings to the form.
          </small>
          <small class="font-bold">
            Need help finding your rss feed? See this
            <a
              href="https://help.spreaker.com/en/articles/4258360-where-can-i-find-my-rss-feed"
              target="_blank"
            >
              article
            </a>
          </small>

          <Message
            class="mt-1 mb-2"
            severity="secondary"
            icon="pi pi-exclamation-triangle"
            :closable="false"
            :pt="{
              icon: {
                class: 'mt-1 text-xl align-self-start',
              },
              text: {
                class: 'font-normal text-sm',
              },
            }"
          >
            Upon form submission, you must validate your spreaker show.
            We will send a validation email to your host email.
          </Message>
        </template>
      </BaseInput>
    </div>
    <div class="col-12">
      <BaseInput
        :modelValue="name"
        @update:modelValue="$emit('update:name', $event)"
        :fieldId="nameFieldName"
        :fieldName="nameFieldName"
        fieldLabel="Podcast Name"
      />
    </div>
    <div class="col-12 md:col-6">
      <BaseInput
        :modelValue="hostName"
        @update:modelValue="$emit('update:hostName', $event)"
        :fieldId="hostNameFieldName"
        :fieldName="hostNameFieldName"
        fieldLabel="Host Name"
      />
    </div>
    <div class="col-12 md:col-6">
      <BaseInput
        :modelValue="hostEmail"
        @update:modelValue="$emit('update:hostEmail', $event)"
        :fieldId="hostEmailFieldName"
        :fieldName="hostEmailFieldName"
        fieldLabel="Host Email"
        type="email"
        disabled
        helperText="This value is populated from your Spreaker RSS feed."
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rssUrl: String,
    name: String,
    hostName: String,
    hostEmail: String,
    rssUrlFieldName: {
      type: String,
      default: 'rssUrl',
    },
    nameFieldName: {
      type: String,
      default: 'name',
    },
    hostNameFieldName: {
      type: String,
      default: 'hostName',
    },
    hostEmailFieldName: {
      type: String,
      default: 'hostEmail',
    },
  },
};
</script>
