<template>
  <BaseFieldContainer
    :class="{
      'password-meter-open': passwordAttrs.feedback
        && $refs.passwordInput
        && $refs.passwordInput.focused,
    }"
  >
    <VeeField
      :name="fieldName"
      v-model="value"
      v-slot="{ errors, field, value: fieldValue }"
    >
      <label
        v-if="fieldLabel"
        :for="fieldId"
        :class="{ 'text-red-400': errors.length > 0}"
      >
        {{ fieldLabel }}
      </label>
      <Password
        :id="fieldId"
        v-bind="{
          ...passwordAttrs,
          ...field,
        }"
        :modelValue="fieldValue"
        :invalid="errors.length > 0"
        ref="passwordInput"
      >
        <template #showicon="{toggleCallback}">
          <i
            class="p-icon p-input-icon pi pi-eye-slash"
            v-tooltip.top="'Show password'"
            @click="toggleCallback"
            @keyDown="toggleCallback"
          />
        </template>
        <template #hideicon="{toggleCallback}">
          <i
            class="p-icon p-input-icon pi pi-eye"
            v-tooltip.top="'Hide password'"
            @click="toggleCallback"
            @keyDown="toggleCallback"
          />
        </template>
      </Password>
      <small
        v-if="errors.length > 0"
        class="text-red-400"
      >
        {{ errors[0] }}
      </small>
    </VeeField>
  </BaseFieldContainer>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    passwordAttrs() {
      return {
        ...this.defaultAttrs,
        ...this.$attrs,
      };
    },
    defaultAttrs() {
      return {
        toggleMask: true,
        feedback: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.password-meter-open {
  padding-bottom: 68px;
}
</style>
