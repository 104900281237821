<template>
  <div></div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores';
import { ROUTE_LOGIN } from '@/router/routes';

export default {
  computed: {
    ...mapStores(useAuthStore),
  },
  mounted() {
    this.authStore.logout();
    this.$router.push({
      name: ROUTE_LOGIN,
    });
  },
};
</script>
