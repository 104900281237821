<template>
  <Message
    class="mt-0"
    severity="secondary"
    icon="pi pi-info-circle"
    :closable="false"
    :pt="{
      icon: {
        class: 'mt-1 text-xl align-self-start',
      },
      text: {
        class: 'font-normal text-sm',
      },
    }"
  >
    Ad targeting is set to US only at this time.
  </Message>
</template>

<script>
export default {
};
</script>
