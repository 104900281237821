<template>
   <BaseFieldContainer>
      <VeeField
        :name="fieldName"
        v-model="value"
        v-slot="{ errors, value: fieldValue, handleChange }"
      >
        <label
          v-if="fieldLabel"
          :for="fieldId"
          :class="{ 'text-red-400': errors.length > 0}"
        >
          {{ fieldLabel }}
        </label>
        <slot name="helperText">
          <small
            v-if="helperText"
            :id="`${fieldId}-help`"
            v-html="helperText"
          />
        </slot>
        <Calendar
          :inputId="fieldId"
          v-bind="{
            ...$attrs,
          }"
          :modelValue="fieldValue"
          @update:modelValue="handleChange"
          :invalid="errors.length > 0"
          :aria-describedby="helperText ? `${fieldId}-help` : ''"
        />
        <small
          v-if="errors.length > 0"
          class="text-red-400"
        >
          {{ errors[0] }}
        </small>
      </VeeField>
    </BaseFieldContainer>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Object,
    },
    fieldId: {
      type: String,
    },
    fieldName: {
      type: String,
    },
    fieldLabel: {
      type: String,
    },
    helperText: {
      type: String,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
