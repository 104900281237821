<template>
  <div
    ref="container"
  >
    <div
      v-if="showIcon"
      class="scroll-icon-container"
      :style="iconContainerStyles"
    >
      <i
        class="pi pi-angle-double-down
          fadeoutdown animation-duration-1000 animation-iteration-infinite
          text-primary text-2xl"
      />
    </div>
    <slot />
    <div ref="observerTarget" />
  </div>
</template>

<script>
// amount of pixels to push icon up so it has somewhere to fade-out-down into
const ICON_BOTTOM_BUFFER = -20;

export default {
  mounted() {
    this.parentElement = this.$refs
      && this.$refs.container
      && this.$refs.container.parentElement;
    this.observedElement = this.$refs.observerTarget;

    if (!this.observer && this.parentElement && this.observedElement) {
      const options = {
        root: this.parentElement,
        threshold: 1,
      };

      this.observer = new IntersectionObserver(this.observerCallback, options);
      this.observer.observe(this.observedElement);
    }

    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
    if (this.observer && this.observedElement) {
      this.observer.unobserve(this.observedElement);
    }

    window.removeEventListener('resize', this.onResize);
  },
  data() {
    return {
      parentElement: null,
      observedElement: null,
      observer: null,
      showIcon: false,
      iconContainerStyles: {},
    };
  },
  methods: {
    setIconContainerPosition() {
      if (!this.parentElement) {
        return;
      }

      const {
        left,
        width,
        bottom,
      } = this.parentElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      this.iconContainerStyles = {
        width: `${width}px`,
        left: `${left}px`,
        bottom: `${viewportHeight - bottom - ICON_BOTTOM_BUFFER}px`,
      };
    },
    onResize() {
      this.setIconContainerPosition();
    },
    observerCallback([entry]) {
      this.showIcon = !entry.isIntersecting;

      this.setIconContainerPosition();
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-icon-container {
  position: fixed;
  z-index: 200;
  text-align: center;
  pointer-events: none;
}
</style>
