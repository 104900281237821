<template>
  <div class="grid">
    <div class="col-12">
      <BaseInput
        :modelValue="rssUrl"
        @update:modelValue="$emit('update:rssUrl', $event)"
        :fieldId="rssUrlFieldName"
        :fieldName="rssUrlFieldName"
        fieldLabel="Podcast Hosting Platform RSS"
        @blur="$emit('blurRssUrl', $event)"
      >
        <template #helperText>
          <small :id="`${rssUrlFieldName}-help`">
            Enter your podcast hosting platform rss feed.
            This field will try to import your settings to the form.
          </small>
          <small class="font-bold">
            Need help finding your rss feed? See this
            <a
              href="https://help.tritondigital.com/docs/where-is-my-rss-feed?highlight=rss%20feed"
              target="_blank"
            >
              article
            </a>
          </small>
        </template>
      </BaseInput>
    </div>
    <div class="col-12">
      <BaseInput
        :modelValue="name"
        @update:modelValue="$emit('update:name', $event)"
        :fieldId="nameFieldName"
        :fieldName="nameFieldName"
        fieldLabel="Podcast Name"
      />
    </div>
    <div class="col-12 md:col-6">
      <BaseInput
        :modelValue="hostName"
        @update:modelValue="$emit('update:hostName', $event)"
        :fieldId="hostNameFieldName"
        :fieldName="hostNameFieldName"
        fieldLabel="Host Name"
      />
    </div>
    <div class="col-12 md:col-6">
      <BaseInput
        :modelValue="hostEmail"
        @update:modelValue="$emit('update:hostEmail', $event)"
        :fieldId="hostEmailFieldName"
        :fieldName="hostEmailFieldName"
        fieldLabel="Host Email"
        type="email"
      />
    </div>
    <div class="col-12 md:col-6">
      <BaseInputNumber
        :modelValue="tapProgramId"
        @update:modelValue="$emit('update:tapProgramId', $event)"
        :fieldId="tapProgramIdFieldName"
        :fieldName="tapProgramIdFieldName"
        fieldLabel="Triton Station ID"
        :format="false"
      >
        <template #helperText>
          <small class="font-bold">
            Need help finding your ID? See these
            <Button
              class="text-sm p-0"
              label="instructions"
              link
              @click="isTritonStationDialogVisible = true"
            />
          </small>
        </template>
      </BaseInputNumber>
    </div>

    <TritonStationHelpDialog
      v-model:visible="isTritonStationDialogVisible"
    />
  </div>
</template>

<script>
import TritonStationHelpDialog from './components/tritonStationHelpDialog';

export default {
  components: {
    TritonStationHelpDialog,
  },
  props: {
    rssUrl: String,
    name: String,
    hostName: String,
    hostEmail: String,
    tapProgramId: {
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
    rssUrlFieldName: {
      type: String,
      default: 'rssUrl',
    },
    nameFieldName: {
      type: String,
      default: 'name',
    },
    hostNameFieldName: {
      type: String,
      default: 'hostName',
    },
    hostEmailFieldName: {
      type: String,
      default: 'hostEmail',
    },
    tapProgramIdFieldName: {
      type: String,
      default: 'tapProgramId',
    },
  },
  data() {
    return {
      isTritonStationDialogVisible: false,
    };
  },
};
</script>
