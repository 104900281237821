<template>
  <div>
    <router-view />
    <Toast
      :pt="{
        summary: {
          class: 'mb-2 block'
        },
        detail: {
          class: 'mt-0',
        },
      }"
      error-icon="pi pi-exclamation-triangle"
    >
    </Toast>
    <DynamicDialog />
  </div>
</template>

<script>
import Toast from 'primevue/toast';
import { compareLocalStorageTokenToStoreToken } from '@/utils/eventListeners';
import DynamicDialog from 'primevue/dynamicdialog';

export default {
  components: {
    Toast,
    DynamicDialog,
  },
  mounted() {
    if ('hidden' in document) {
      document.addEventListener('visibilitychange', compareLocalStorageTokenToStoreToken);
    }
  },
  beforeUnmount() {
    if ('hidden' in document) {
      document.removeEventListener('visibilitychange', compareLocalStorageTokenToStoreToken);
    }
  },
};
</script>
