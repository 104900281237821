<template>
  <Dialog
    :visible="visible"
    modal
    header="Triton Station ID Help"
    @update:visible="$emit('update:visible', $event)"
    :pt="{
      content: {
        style: {
          maxWidth: '600px',
        },
      },
    }"
  >

  <div>
    <ol class="instructions-list">
      <li>
        Log into your
        <a
          class="text-primary"
          href="https://omnystudio.com/login"
          target="_blank"
        >
          OmnyStudio <i class="pi pi-external-link text-xs" />
        </a>
        account.
      </li>
      <li>
        Navigate to the "Programs" page and click into your podcast.
      </li>
      <li>
        In the left hand menu, click "Program settings".
      </li>
      <li>
        Scroll down to the "Additional information" section and look for "Triton Station ID".
        The ID should be a 6 digit number.
      </li>
    </ol>
  </div>

  <template #footer>
      <div class="flex justify-content-end">
        <Button
          label="Close"
          @click="$emit('update:visible', false)"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.instructions-list {
  margin-top: 0;

  li:not(first-child) {
    margin-top: 0.5rem;
  }
}
</style>
