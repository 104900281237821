export const AD_STATUS_CREATED = 'created';
/**
 * @deprecated in favor of 'awaitingApproval'
 */
export const AD_STATUS_PENDING = 'pending';
export const AD_STATUS_AWAITING_APPROVAL = 'awaitingApproval';
/**
 * @deprecated in favor of 'running'
 */
export const AD_STATUS_ACTIVE = 'active';
export const AD_STATUS_RUNNING = 'running';
export const AD_STATUS_PAUSED = 'paused';
export const AD_STATUS_COMPLETED = 'completed';
export const AD_STATUS_DECLINED = 'declined';
