import { defineStore } from 'pinia';

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    appName: process.env.VUE_APP_APP_NAME,
    paymentSystemEnabled: process.env.VUE_APP_PAYMENT_SYSTEM_ENABLED === 'true',
  }),
  getters: {},
  actions: {},
});
