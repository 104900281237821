import { useAuthStore, useMyUserStore, useRolesStore } from '@/stores';
import { generateUserSetupKey } from '@/utils/localStorageKeys';
import { QUERY_PARAM_PATH_AFTER_LOGIN } from '@/constants';
import {
  ROUTE_ADVERTISER_DASHBOARD,
  ROUTE_ADVERTISER_SETUP,
  ROUTE_ERROR,
  ROUTE_HOST_DASHBOARD,
  ROUTE_HOST_HOME,
  ROUTE_HOST_SETUP,
  ROUTE_HOSTING_PLATFORM_DASHBOARD,
  ROUTE_HOSTING_PLATFORM_LOGIN,
  ROUTE_LOGIN,
  ROUTE_UNAUTHORIZED,
} from './routes';

export const publicGuard = (to, from, next) => {
  const authStore = useAuthStore();
  if (!authStore.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

export const authHostGuard = async (to) => {
  try {
    const authStore = useAuthStore();
    const myUserStore = useMyUserStore();
    const rolesStore = useRolesStore();

    await myUserStore.getMyUser();

    if (!authStore.isAuthenticated) {
      return {
        query: {
          [QUERY_PARAM_PATH_AFTER_LOGIN]: to.fullPath,
        },
        name: ROUTE_LOGIN,
      };
    }

    await rolesStore.getRoles();

    if (myUserStore.isHostUser) {
      // allow user to navigate to setup page if exact match.
      // this prevents infinite loop when userHasSeenSetupPage === false
      if (to.name === ROUTE_HOST_SETUP) {
        return true;
      }

      const setupKey = generateUserSetupKey(myUserStore.userId);
      const userHasSeenSetupPage = !!localStorage.getItem(setupKey);
      if (userHasSeenSetupPage) {
        return true;
      }
      return {
        name: ROUTE_HOST_SETUP,
      };
    }
    if (myUserStore.isAdvertiserUser) {
      return { name: ROUTE_ADVERTISER_DASHBOARD };
    }
    if (myUserStore.isHostingPlatformUser) {
      return { name: ROUTE_HOSTING_PLATFORM_DASHBOARD };
    }

    return { name: ROUTE_UNAUTHORIZED };
  } catch (error) {
    return { name: ROUTE_ERROR };
  }
};

export const beforeEnterHostSetupGuard = (to) => {
  const authStore = useAuthStore();
  const myUserStore = useMyUserStore();

  if (!authStore.isAuthenticated) {
    return {
      query: {
        [QUERY_PARAM_PATH_AFTER_LOGIN]: to.fullPath,
      },
      name: ROUTE_LOGIN,
    };
  }

  const setupKey = generateUserSetupKey(myUserStore.userId);
  localStorage.setItem(setupKey, true);

  return true;
};

export const authAdvertiserGuard = async (to) => {
  try {
    const authStore = useAuthStore();
    const myUserStore = useMyUserStore();
    const rolesStore = useRolesStore();

    await myUserStore.getMyUser();

    if (!authStore.isAuthenticated) {
      return {
        query: {
          [QUERY_PARAM_PATH_AFTER_LOGIN]: to.fullPath,
        },
        name: ROUTE_LOGIN,
      };
    }

    await rolesStore.getRoles();

    if (myUserStore.isAdvertiserUser) {
      // allow user to navigate to setup page if exact match.
      // this prevents infinite loop when userHasSeenSetupPage === false
      if (to.name === ROUTE_ADVERTISER_SETUP) {
        return true;
      }

      const setupKey = generateUserSetupKey(myUserStore.userId);
      const userHasSeenSetupPage = !!localStorage.getItem(setupKey);
      if (userHasSeenSetupPage) {
        return true;
      }
      return {
        name: ROUTE_ADVERTISER_SETUP,
      };
    }

    if (myUserStore.isHostUser) {
      return { name: ROUTE_HOST_DASHBOARD };
    }

    if (myUserStore.isHostingPlatformUser) {
      return { name: ROUTE_HOSTING_PLATFORM_DASHBOARD };
    }

    return { name: ROUTE_UNAUTHORIZED };
  } catch (error) {
    return { name: ROUTE_ERROR };
  }
};

export const beforeEnterAdvertiserSetupGuard = (to) => {
  const authStore = useAuthStore();
  const myUserStore = useMyUserStore();

  if (!authStore.isAuthenticated) {
    return {
      query: {
        [QUERY_PARAM_PATH_AFTER_LOGIN]: to.fullPath,
      },
      name: ROUTE_LOGIN,
    };
  }

  const setupKey = generateUserSetupKey(myUserStore.userId);
  localStorage.setItem(setupKey, true);

  return true;
};

export const authHostingPlatformGuard = async (to, from, next) => {
  try {
    const authStore = useAuthStore();
    const myUserStore = useMyUserStore();
    const rolesStore = useRolesStore();

    await myUserStore.getMyUser();

    if (!authStore.isAuthenticated) {
      next({
        query: {
          [QUERY_PARAM_PATH_AFTER_LOGIN]: to.fullPath,
        },
        name: ROUTE_HOSTING_PLATFORM_LOGIN,
      });
      return;
    }

    await rolesStore.getRoles();

    if (myUserStore.isHostingPlatformUser) {
      next();
    } else if (myUserStore.isHostUser) {
      next({ name: ROUTE_HOST_DASHBOARD });
    } else if (myUserStore.isAdvertiserUser) {
      next({ name: ROUTE_ADVERTISER_DASHBOARD });
    } else {
      next({ name: ROUTE_UNAUTHORIZED });
    }
  } catch (error) {
    next({ name: ROUTE_ERROR });
  }
};

export const beforeEnterHostOrganizationGuard = async (to, from, next) => {
  const myUserStore = useMyUserStore();

  if (myUserStore.myOrganization) {
    next();
  } else {
    next({ name: ROUTE_HOST_HOME });
  }
};
