export const generateBodyText = ({
  bodyText = '',
  userId,
  url,
}) => {
  const debugDivider = '-------- DEBUG INFORMATION - do not edit below this line --------';
  const userIdRow = `user id: ${userId}`;
  const urlRow = `url: ${url}`;

  return encodeURIComponent(`${bodyText}\n\n${debugDivider}\n\n${userIdRow}\n${urlRow}\n`);
};
