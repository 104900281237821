<template>
  <Card
    :class="{
      'analytics-card': true,
      'border-left-green': percentageChange > 0,
      'border-left-red': percentageChange < 0,
    }"
    :pt="{
      title: {
        class: 'text-lg',
      }
    }"
  >
    <!-- <template #title>Total Impressions</template> -->
    <template #content>
      <div class="grid">
        <div class="col-12 sm:col sm:flex-grow-1">
          <div class="p-card-title text-lg mb-0">
            Total Impressions
          </div>
        </div>
        <div class="col-12 sm:col sm:flex-grow-0">
          <Dropdown
            v-model="selectedOption"
            :options="OPTIONS"
            optionLabel="label"
            @change="getImpressions"
          />
        </div>
      </div>

      <p class="mt-0 mb-2 text-2xl font-bold">
        <template v-if="totalImpressionsAreLoading">
          <Skeleton
            width="120px"
            height="29px"
          />
        </template>
        <template v-else>
          {{ formattedTotalImpressions }}
        </template>
      </p>

      <Skeleton
        v-if="comparatorIsLoading"
        width="100px"
        height="19px"
      />
      <div
        v-else
        :class="{
          'text-sm': true,
          'text-green-400': percentageChange > 0,
          'text-red-400': percentageChange < 0,
        }"
      >
        <i class="pi pi-arrow-circle-up" />
        <span class="ml-1">
          {{ percentageChange > 0 ? `+${percentChangeFormatted}` : percentChangeFormatted }}%
          {{ selectedOption.comparatorText }}
        </span>
      </div>
    </template>
  </Card>
</template>

<script>
import { DateTime } from 'luxon';
import { mapStores } from 'pinia';
import { useAnalyticsStore } from '@/stores';
import { parseMessageFromError } from '@/utils/errors';

const OPTIONS = [
  {
    label: 'Month to Date',
    startDate: DateTime.local()
      .startOf('month')
      .toUTC()
      .toISODate(),
    endDate: DateTime.now()
      .toUTC()
      .toISODate(),
    dataKey: 'monthToDateAds',
    comparatorStartDate: DateTime.local()
      .startOf('month')
      .minus({
        months: 1,
      })
      .toUTC()
      .toISODate(),
    comparatorEndDate: DateTime.now()
      .minus({
        months: 1,
      })
      .toUTC()
      .toISODate(),
    comparatorDataKey: 'monthToDateAdsComparator',
    comparatorText: 'compared to previous month to date',
  },
  {
    label: 'Previous Month',
    startDate: DateTime.local()
      .startOf('month')
      .minus({
        months: 1,
      })
      .toUTC()
      .toISODate(),
    endDate: DateTime.local()
      .endOf('month')
      .minus({
        months: 1,
      })
      .toUTC()
      .toISODate(),
    dataKey: 'previousMonthAds',
    comparatorStartDate: DateTime.local()
      .startOf('month')
      .minus({
        months: 2,
      })
      .toUTC()
      .toISODate(),
    comparatorEndDate: DateTime.local()
      .endOf('month')
      .minus({
        months: 2,
      })
      .toUTC()
      .toISODate(),
    comparatorDataKey: 'previousMonthAdsComparator',
    comparatorText: 'since previous month',
  },
  {
    label: '2 Months Ago',
    startDate: DateTime.local()
      .startOf('month')
      .minus({
        months: 2,
      })
      .toUTC()
      .toISODate(),
    endDate: DateTime.local()
      .endOf('month')
      .minus({
        months: 2,
      })
      .toUTC()
      .toISODate(),
    dataKey: 'twoMonthsAgoAds',
    comparatorStartDate: DateTime.local()
      .startOf('month')
      .minus({
        months: 3,
      })
      .toUTC()
      .toISODate(),
    comparatorEndDate: DateTime.local()
      .endOf('month')
      .minus({
        months: 3,
      })
      .toUTC()
      .toISODate(),
    comparatorDataKey: 'twoMonthsAgoAdsComparator',
    comparatorText: 'since previous month',
  },
];

// TODO - move this to it's own file or a helpers/utils file
// source - https://stackoverflow.com/a/54115558/7176268
const percentIncrease = (a, b) => {
  let percent;
  if (b !== 0) {
    if (a !== 0) {
      percent = ((b - a) / a) * 100;
    } else {
      percent = b * 100;
    }
  } else if (b === 0 && a === 0) {
    percent = 0;
  } else {
    percent = -a * 100;
  }
  return Math.floor(percent);
};

export default {
  props: {
    adIds: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    ...mapStores(useAnalyticsStore),
    formattedTotalImpressions() {
      const { dataKey } = this.selectedOption;
      const impressions = this.analyticsStore.adImpressions[dataKey] || [];

      const totalImpressions = impressions.reduce((acc, item) => acc + item.count, 0);

      return totalImpressions.toLocaleString('en-US', {
        minimumFractionDigits: 0,
      });
    },
    totalImpressionsAreLoading() {
      const { dataKey } = this.selectedOption;

      return this.analyticsStore.adImpressionsAreLoading[dataKey] || false;
    },
    comparatorIsLoading() {
      const { dataKey, comparatorDataKey } = this.selectedOption;

      return this.analyticsStore.adImpressionsAreLoading[dataKey]
        || this.analyticsStore.adImpressionsAreLoading[comparatorDataKey]
        || false;
    },
    percentageChange() {
      const { dataKey, comparatorDataKey } = this.selectedOption;

      const impressions = this.analyticsStore.adImpressions[dataKey] || [];
      const totalImpressions = impressions.reduce((acc, item) => acc + item.count, 0);

      const comparatorImpressions = this.analyticsStore.adImpressions[comparatorDataKey] || [];
      const comparatorTotalImpressions = comparatorImpressions
        .reduce((acc, item) => acc + item.count, 0);

      const percentChange = percentIncrease(comparatorTotalImpressions, totalImpressions);

      return percentChange;
    },
    percentChangeFormatted() {
      return this.percentageChange.toLocaleString('en-US', {
        minimumFractionDigits: 0,
      });
    },
  },
  watch: {
    adIds: {
      immediate: true,
      handler() {
        this.getImpressions();
      },
    },
  },
  data() {
    return {
      OPTIONS,
      selectedOption: OPTIONS[0],
    };
  },
  methods: {
    async getImpressions() {
      try {
        if (this.adIds.length === 0) {
          return;
        }

        const {
          startDate,
          endDate,
          dataKey,
          comparatorStartDate,
          comparatorEndDate,
          comparatorDataKey,
        } = this.selectedOption;

        const dataPromise = this.analyticsStore.getGroupedAdImpressions({
          startDate,
          endDate,
          adIds: this.adIds,
          dataKey,
        });
        const comparatorPromise = this.analyticsStore.getGroupedAdImpressions({
          startDate: comparatorStartDate,
          endDate: comparatorEndDate,
          adIds: this.adIds,
          dataKey: comparatorDataKey,
        });

        await Promise.all([dataPromise, comparatorPromise]);
      } catch (error) {
        const message = parseMessageFromError(error, 'Error fetching total impressions');

        this.$toast.add({
          severity: 'error',
          detail: message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-card {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    border-radius: 12px 0 0 12px;
    background-color: var(--gray-400)
  }

  &.border-left-green {
    &:before {
      background-color: var(--green-400)
    }
  }
  &.border-left-red {
    &:before {
      background-color: var(--red-400)
    }
  }
}
</style>
