export const HOSTING_PLATFORM_SPREAKER = 'spreaker';
export const HOSTING_PLATFORM_TRITON = 'triton';

export const HOSTING_PLATFORM_OPTIONS = [
  {
    value: HOSTING_PLATFORM_SPREAKER,
    label: 'Spreaker',
  },
  {
    value: HOSTING_PLATFORM_TRITON,
    label: 'Triton/Omny',
  },
];
